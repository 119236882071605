$primary: #093759;
$info: #4b738f;

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';

@import '~admin-lte/build/scss/adminlte';

@import "~bootstrap/scss/bootstrap";
@import '~react-toastify/scss/main';


//@import '~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.css';

//@import "primereact/resources/primereact.min.css";
//@import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

@import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
@import "primereact/resources/primereact.min.css";                  //core css
//@import "primeicons/primeicons.css";                                //icons

.breadcrumb-item {
  font-size: 0.75rem;
}

.content-header {
  padding-top: 5px;
  padding-bottom: 5px;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

body {
  font-family: Roboto Medium, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}
